import React, { useState, useEffect } from "react";
import Layout from "../layouts/Layout";
import SiteMetadata from "../components/SiteMetaData";
import { BuyMeACoffee, checkRole, MySpinner } from "../components/Utils";
import Counter from "../components/Counter";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { FaArrowCircleLeft, FaEdit } from "react-icons/fa";
import { Textarea } from "@chakra-ui/react";
import "regenerator-runtime/runtime";

const NotesPage = () => {
  const [editingMode, setEditingMode] = useState(false);
  const { user } = useAuth0();
  const [spinner, setSpinner] = useState(false);
  const [text, setText] = useState("dummy");
  const [value, setValue] = useState("dummy");

  let handleInputChange = (e) => {
    setValue(e.target.value);
  };
  const contentful = require("contentful-management");
  async function Connect() {
    let client = contentful.createClient({
      accessToken: "CFPAT-jLjpO9lQUXtJ4wgWWXTuwdayKLQMXWZ0s7aUsX9km3U",
    });
    let space = await client.getSpace("j3cnxbdaejpl");
    return await space.getEnvironment("master");
  }
  async function ShowNote(env, postID) {
    let post = await env.getEntry(postID);
    setText(post.fields.note?.["de"]);
    if (value === "dummy") {
      setValue(post.fields.note?.["de"]);
    }
  }
  useEffect(() => {
    (async () => {
      let env = await Connect();
      await ShowNote(env, "16MnFGNfPnxbeQ3UvOb1H0");
    })();
  }, []);

  const handleClick = () => {
    async function UpdateNote(env, postID) {
      setSpinner(true);
      setEditingMode(false);
      let post = await env.getEntry(postID);
      post.fields.note = { de: value };
      await setText(post.fields.note["de"]);
      setSpinner(false);
      await post.update();
      post = await env.getEntry(postID);
      await post.publish();
    }
    (async () => {
      let env = await Connect();
      await UpdateNote(env, "16MnFGNfPnxbeQ3UvOb1H0");
    })();
  };

  const NotizenSidebar = () => {
    return (
      <div>
        <div className="hidden md:contents">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-3 mb-4">
            <div className="text-xl mb-5">Trivia</div>
            <Counter />
          </div>
        </div>
        <BuyMeACoffee />
      </div>
    );
  };
  return (
    <Layout>
      <SiteMetadata title="Notizen" description="Meine Notizen" />
      <div className="bg-gray-50 pb-6 flex-grow dark:bg-gray-900 dark:text-white">
        <div className="container">
          <div className="text-center text-4xl py-6 lg:hidden">Notizen</div>
          <div className="md:grid grid-cols-4 gap-3">
            <div className="md:col-span-3 mb-8 md:mb-0 relative">
              {text === "dummy" && <MySpinner />}
              <div className={text === "dummy" ? "invisible" : ""}>
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden transform p-3 h-full">
                  {checkRole(user, "admin") && (
                    <div>
                      {spinner && <MySpinner />}
                      <div className="flex justify-between items-center mb-5">
                        <div className="flex">
                          <div className="font-bold hidden md:contents">
                            Anmerkungen:
                          </div>
                          {(value == null || value === "") && (
                            <div className="font-normal ml-1">Keine</div>
                          )}
                        </div>
                        <button onClick={() => setEditingMode(!editingMode)}>
                          {editingMode ? (
                            <FaArrowCircleLeft
                              size={25}
                              className="text-red-600"
                            />
                          ) : (
                            <FaEdit size={25} className="text-blue-700" />
                          )}
                        </button>
                      </div>
                      {editingMode ? (
                        <>
                          <Textarea
                            rows={20}
                            value={value}
                            onChange={handleInputChange}
                            placeholder="Nur zu!"
                          />

                          <button
                            className="submitButton"
                            onClick={handleClick}
                          >
                            Speichern
                          </button>
                        </>
                      ) : (
                        <div className="whitespace-pre-wrap">{text}</div>
                      )}
                    </div>
                  )}
                  {!checkRole(user, "admin") && (
                    <div>Du hast keinen Zugriff auf diese Seite.</div>
                  )}
                </div>
              </div>
            </div>
            <NotizenSidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withAuthenticationRequired(NotesPage);
